// 引入element ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import globalImport from './globalImport'
// 移动端布局
// import "lib-flexible-computer"

import router from './router'
import store from './store'
import {
	createApp
} from 'vue'
import App from './App.vue'


const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(globalImport);

//挂载app
app.mount('#app');
