<template>
	<div class="user-bar">
		<div class="screen panel-item hidden-sm-and-down" @click="screen">
			<el-icon><el-icon-full-screen /></el-icon>
		</div>
		<!-- 	<div class="msg panel-item" @click="showMsg">
			<el-badge :hidden="msgList.length==0" :value="msgList.length" class="badge" type="danger">
				<el-icon><el-icon-chat-dot-round /></el-icon>
			</el-badge>
			<el-drawer title="新消息" v-model="msg" :size="400" append-to-body destroy-on-close>
				<el-container>
					<el-main class="nopadding">
						<el-scrollbar>
							<ul class="msg-list">
								<li v-for="item in msgList" v-bind:key="item.id">
									<a :href="item.link" target="_blank">
										<div class="msg-list__icon">
											<el-badge is-dot type="danger">
												<el-avatar :size="40" :src="item.avatar"></el-avatar>
											</el-badge>
										</div>
										<div class="msg-list__main">
											<h2>{{item.title}}</h2>
											<p>{{item.describe}}</p>
										</div>
										<div class="msg-list__time">
											<p>{{item.time}}</p>
										</div>
									</a>
								</li>
								<el-empty v-if="msgList.length==0" description="暂无新消息" :image-size="100"></el-empty>
							</ul>
						</el-scrollbar>
					</el-main>
					<el-footer>
						<el-button type="primary" size="small">消息中心</el-button>
						<el-button size="small" @click="markRead">全部设为已读</el-button>
					</el-footer>
				</el-container>
			</el-drawer>
		</div> -->
		<el-dropdown class="user panel-item" trigger="click" @command="handleUser">
			<div class="user-avatar">
				<el-avatar :size="30">{{ userNameF }}</el-avatar>
				<label>{{ userName }}</label>
				<el-icon class="el-icon--right"><el-icon-arrow-down /></el-icon>
			</div>
			<template #dropdown>
				<el-dropdown-menu>
					<el-dropdown-item command="uc">修改密码</el-dropdown-item>
					<el-dropdown-item command="clearCache">清除缓存</el-dropdown-item>
					<el-dropdown-item divided command="outLogin">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</template>
		</el-dropdown>
		<!-- 修改密码 -->
		<el-dialog v-model="dialogEdit" title="修改密码" width="30%" :fullscreen="fullscreenshow">
			<el-form :model="ruleForm" status-icon :rules="rules" ref="addruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="密码" prop="pass"><el-input v-model="ruleForm.pass" autocomplete="off"></el-input></el-form-item>
				<el-form-item label="确认密码" prop="checkPass"><el-input v-model="ruleForm.checkPass" autocomplete="off"></el-input></el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
					<el-button @click="resetClick('addruleForm')">重置</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.ruleForm.checkPass !== '') {
					this.$refs.addruleForm.validateField('checkPass');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.pass) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			ruleForm: {
				pass: '',
				checkPass: ''
			},
			rules: {
				pass: [{ validator: validatePass, trigger: 'blur' }],
				checkPass: [{ validator: validatePass2, trigger: 'blur' }]
			},
			dialogEdit: false,
			fullscreenshow: false,
			userName: '',
			userNameF: '',
			msg: false,
			msgList: [
				{
					id: 1,
					type: 'user',
					avatar: 'img/avatar.jpg',
					title: 'Skuya',
					describe: '如果喜欢就点个星星支持一下哦',
					link: 'https://gitee.com/lolicode/scui',
					time: '5分钟前'
				},
				{
					id: 2,
					type: 'user',
					avatar: 'img/avatar2.gif',
					title: 'Lolowan',
					describe: '点进去Gitee获取最新开源版本',
					link: 'https://gitee.com/lolicode/scui',
					time: '14分钟前'
				},
				{
					id: 3,
					type: 'system',
					avatar: 'img/logo.png',
					title: '感谢登录SCUI Admin',
					describe: 'Vue 3.0 + Vue-Router 4.0 + ElementPlus + Axios 后台管理系统。',
					link: 'https://gitee.com/lolicode/scui',
					time: '2020年7月24日'
				}
			]
		};
	},
	created() {
		var userInfo = this.$TOOL.data.get('USER_INFO');
		this.userName = userInfo.nickname;
		this.userNameF = this.userName.substring(0, 1);

		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
	},
	methods: {
		//个人信息
		handleUser(command) {
			if (command == 'uc') {
				this.dialogEdit = true;
			}
			if (command == 'cmd') {
				this.$router.push({ path: '/cmd' });
			}
			if (command == 'clearCache') {
				this.$confirm('清除缓存会将系统初始化，包括登录状态，是否继续？', '提示', {
					type: 'info'
				})
					.then(() => {
						const loading = this.$loading();
						this.$TOOL.data.clear();
						this.$router.replace({ path: '/login' });
						setTimeout(() => {
							loading.close();
							location.reload();
						}, 1000);
					})
					.catch(() => {
						//取消
					});
			}
			if (command == 'outLogin') {
				this.$confirm('确认是否退出当前用户？', '提示', {
					type: 'warning',
					confirmButtonText: '退出',
					cancelButtonText: '取消',
					confirmButtonClass: 'el-button--primary'
				})
					.then(() => {
						this.$router.replace({ path: '/login' });
					})
					.catch(() => {
						//取消退出
					});
			}
		},
		// 修改密码
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'user/editPassword',
						{
							username: this.$TOOL.data.get('USER_INFO').username,
							password: this.ruleForm.pass,
							id: this.$TOOL.data.get('USER_INFO').id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									this.$router.replace({ path: '/login' });
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		//全屏
		screen() {
			var element = document.documentElement;
			this.$TOOL.screen(element);
		},
		//显示短消息
		showMsg() {
			this.msg = true;
		},
		//标记已读
		markRead() {
			this.msgList = [];
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		}
	}
};
</script>

<style scoped>
.user-bar {
	display: flex;
	align-items: center;
	height: 100%;
}
.user-bar .panel-item {
	padding: 0 10px;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
}
.user-bar .panel-item i {
	font-size: 16px;
}
.user-bar .panel-item:hover {
	background: rgba(0, 0, 0, 0.1);
}
.user-bar .user-avatar {
	height: 49px;
	display: flex;
	align-items: center;
	color: #fff;
}
.user-bar .user-avatar label {
	display: inline-block;
	margin-left: 5px;
	font-size: 12px;
	cursor: pointer;
}

.el-container.is-vertical {
	height: 100%;
	flex-direction: column;
}
.el-main.nopadding {
	padding: 0;
	background: #fff;
}
.el-footer {
	background: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 13px 15px;
}

.msg-list li {
	border-top: 1px solid #eee;
}
.msg-list li a {
	display: flex;
	padding: 20px;
	text-decoration: none;
}
.msg-list li a:hover {
	background: #ecf5ff;
}
.msg-list__icon {
	width: 40px;
	margin-right: 15px;
}
.msg-list__main {
	flex: 1;
}
.msg-list__main h2 {
	font-size: 15px;
	font-weight: normal;
	color: #333;
}
.msg-list__main p {
	font-size: 12px;
	color: #999;
	line-height: 1.8;
	margin-top: 5px;
}
.msg-list__time {
	width: 100px;
	text-align: right;
	color: #999;
}
</style>
