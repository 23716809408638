import tool from './utils/tool'
import Api from './api/index.js';
import * as elIcons from '@element-plus/icons'
import * as echarts from 'echarts'
// 时间格式化
import moment from 'moment'
moment.locale('zh-cn')

// 刷新获取token
Api.updateHeader('Authorization', tool.data.get('TOKEN'));


export default {
	install(app) {
		//挂载全局对象
		app.config.globalProperties.$TOOL = tool;
		app.config.globalProperties.$HTTP = Api;
		app.config.globalProperties.$moment = moment;
		app.config.globalProperties.$echarts = echarts;
		
		// 统一注册el-icon图标
		for (const icon in elIcons) {
			app.component(`ElIcon${icon}`, elIcons[icon])
		}

	}
}
