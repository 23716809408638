<template>
	<div class="adminui-tags">
		<ul ref="tags">
			<li v-for="tag in tagList" v-bind:key="tag" :class="isActive(tag) ? 'active' : ''">
				<router-link v-if="tag.meta.title == '首页'" :to="{ path: tag.path }">
					<span>{{ tag.meta.title }}</span>
					<el-icon v-if="tag.meta.title != '首页'" @click.prevent.stop="closeSelectedTag(tag)"><el-icon-close /></el-icon>
				</router-link>
				<router-link v-else :to="{ path: tag.path, query:{Id:tag.query.Id}}">
					<span>{{ tag.meta.title }}</span>
					<el-icon v-if="tag.meta.title != '首页'" @click.prevent.stop="closeSelectedTag(tag)"><el-icon-close /></el-icon>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'tags',
	data() {
		return {
			tagList: this.$store.state.viewTags
		};
	},
	watch: {
		$route(e) {
			this.addViewTags(e);
			//判断标签容器是否出现滚动条
			this.$nextTick(() => {
				const tags = this.$refs.tags;
				if (tags && tags.scrollWidth > tags.clientWidth) {
					//确保当前标签在可视范围内
					let targetTag = tags.querySelector('.active');
					targetTag.scrollIntoView();
				}
			});
		}
	},
	created() {
		this.addViewTags({ fullPath: '/index', name: 'index', path: '/index', meta: { title: '首页' } });
		this.addViewTags(this.$route);
	},
	mounted() {
		this.scrollInit();
	},
	methods: {
		//增加tag
		addViewTags(route) {
			if (route.name && !route.meta.fullpage) {
				this.$store.commit('pushViewTags', route);
				this.$store.commit('pushKeepLive', route.name);
			}
		},
		//高亮tag
		isActive(route) {
			return route.fullPath === this.$route.fullPath;
		},
		//关闭tag
		closeSelectedTag(tag, autoPushLatestView = true) {
			this.$store.commit('removeViewTags', tag);
			this.$store.commit('removeKeepLive', tag.name);
			if (autoPushLatestView && this.isActive(tag)) {
				const latestView = this.tagList.slice(-1)[0];
				if (latestView) {
					this.$router.push(latestView);
				} else {
					this.$router.push('/');
				}
			}
		},
		//横向滚动
		scrollInit() {
			const scrollDiv = this.$refs.tags;
			scrollDiv.addEventListener('mousewheel', handler, false) || scrollDiv.addEventListener('DOMMouseScroll', handler, false);
			function handler(event) {
				const detail = event.wheelDelta || event.detail;
				//火狐上滚键值-3 下滚键值3，其他内核上滚键值120 下滚键值-120
				const moveForwardStep = 1;
				const moveBackStep = -1;
				let step = 0;
				if (detail == 3 || (detail < 0 && detail != -3)) {
					step = moveForwardStep * 50;
				} else {
					step = moveBackStep * 50;
				}
				scrollDiv.scrollLeft += step;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.adminui-tags {
	height: 35px;
	background: #fff;
	border-bottom: 1px solid #e6e6e6;
	ul {
		display: flex;
		overflow: hidden;
		li {
			cursor: pointer;
			display: inline-block;
			float: left;
			height: 34px;
			line-height: 34px;
			position: relative;
			flex-shrink: 0;
			&:hover {
				background: #ecf5ff;
			}
			a {
				display: inline-block;
				padding: 0 10px;
				height: 100%;
				color: #999;
				text-decoration: none;
				display: flex;
				align-items: center;
				i {
					margin-left: 10px;
					border-radius: 3px;
					width: 18px;
					height: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
					&:hover {
						background: rgba(0, 0, 0, 0.2);
						color: #fff;
					}
				}
			}
		}
		li.active {
			background: #409eff;
			a {
				color: #fff;
			}
		}
	}
}
</style>
