<template>
	<div class="home" :class="{ activehe: colorCnet }">
		<el-container>
			<el-aside :class="[menuIsCollapse ? 'aminui-side isCollapse' : 'aminui-side', { act: isact }]">
				<div class="adminui-side-top" v-if="!menuIsCollapse">
					<h2>{{ pmenu.meta.title }}</h2>
				</div>
				<el-scrollbar>
					<el-menu :collapse="menuIsCollapse" :default-active="$route.path" router class="el-menu-vertical-demo" :unique-opened="true">
						<template v-for="(item, i) in menu" :key="i">
							<el-sub-menu :index="item.sort + ''" v-if="item.parentId == 0">
								<template #title>
									<el-icon><component :is="item.icon || 'el-icon-menu'" /></el-icon>
									<span>{{ item.name }}</span>
								</template>
								<template v-for="(ine, j) in menu" :key="j">
									<el-menu-item v-if="ine.parentId == item.id" :index="ine.url" :route="{ path: ine.url, query: { Id: ine.id } }" @click="adf">
										<el-icon><component :is="ine.icon || 'el-icon-menu'" /></el-icon>
										<span>{{ ine.name }}</span>
									</el-menu-item>
								</template>
							</el-sub-menu>
						</template>
					</el-menu>
				</el-scrollbar>
			</el-aside>
			<el-container>
				<el-header>
					<Topbar><userbar></userbar></Topbar>
					<Tags></Tags>
				</el-header>
				<div class="adminui-main" id="adminui-main">
					<router-view v-slot="{ Component }">
						<keep-alive :include="this.$store.state.keepLiveRoute"><component :is="Component" :key="$route.fullPath" /></keep-alive>
					</router-view>
				</div>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import Topbar from '../components/topbar.vue';
import userbar from '../components/userbar.vue';
import Tags from '../components/tags.vue';
export default {
	name: 'home',
	components: {
		Topbar,
		userbar,
		Tags
	},
	data() {
		return {
			menu: [],
			navMenus: [],
			menuIsCollapse: false,
			pmenu: {},
			colorCnet: '',
			windowWidth: document.documentElement.clientWidth,
			isact: false
		};
	},
	created() {
		// 判断生产还是测试
		this.colorCnet = this.$TOOL.data.get('COLORSHOWHIDE');

		this.showThis();
		// 获取菜单
		this.menu = this.$TOOL.data.get('USER_INFO').sysResourcesEntities;

		if (document.body.clientWidth < 768) {
			document.getElementsByTagName('body')[0].className = 'equipment-body';
		} else {
			document.body.removeAttribute('class', 'equipment-body');
		}
	},
	watch: {
		$route() {
			this.showThis();
		},
		'$store.state.menuIsCollapse'() {
			// 获取转盘配置
			this.menuIsCollapse = this.$store.state.menuIsCollapse;
		},
		'$store.state.isact'() {
			// 获取转盘配置
			this.isact = this.$store.state.isact;
		},
		windowWidth() {
			let that = this;
			if (that.windowWidth < 768) {
				document.getElementsByTagName('body')[0].className = 'equipment-body';
			} else {
				document.body.removeAttribute('class', 'equipment-body');
			}
		}
	},
	methods: {
		adf() {
			this.$TOOL.data.remove('USERID');
		},
		//路由监听
		showThis() {
			this.pmenu = this.$route;
		}
	},
	mounted() {
		var that = this;
		// <!--把window.onresize事件挂在到mounted函数上-->
		window.onresize = () => {
			return (() => {
				window.fullWidth = document.documentElement.clientWidth;
				that.windowWidth = window.fullWidth; // 宽
			})();
		};
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
	height: 100%;
	.el-container {
		height: 100%;
		.aminui-side-split {
			width: 65px;
			background-color: #222b45;
			.adminui-side-split-scroll {
				overflow: auto;
				overflow-x: hidden;
				height: 100%;
				li {
					width: 65px;
					height: 65px;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					cursor: pointer;
					&:hover {
						background-color: rgba(255, 255, 255, 0.1);
					}
					i {
						font-size: 18px;
					}
					p {
						margin-top: 5px;
					}
				}
				li.active {
					background-color: #409eff;
				}
			}
		}
		.aminui-side {
			box-shadow: none;
		}
		.el-aside {
			&.isCollapse {
				width: 64px;
			}
			background-color: #fff;
			display: flex;
			flex-flow: column;
			width: 210px;
			background: #fff;
			border-right: 1px solid #e6e6e6;
			transition: width 0.3s;
			.adminui-side-top {
				border-bottom: 1px solid #ebeef5;
				height: 50px;
				line-height: 50px;
				background-color: #409eff;
				h2 {
					padding: 0 20px;
					font-size: 17px;
					color: #fff;
				}
			}
			.el-menu {
				border: none !important;
			}
			.el-menu-item.is-active {
				background: #ecf5ff;
			}
		}
		.el-header {
			padding: 0;
			height: auto;
			display: block;
			border: none;
		}
		.adminui-main {
			overflow: auto;
			background-color: #f6f8f9;
			flex: 1;
		}
	}
}
</style>
