<template>
	<div class="adminui-topbar">
		<div class="left-panel">
			<div class="menu-collapse" @click="dcClick(isack)">
				<el-icon :size="16"><el-icon-fold /></el-icon>
			</div>
			<div class="logo_center">
				<div class="namce">产品：</div>
				<el-select v-model="couponSelected" placeholder="请选择" size="default" @change="clselete">
					<el-option v-for="item in options" :key="item.key" :label="item.value" :value="item.key"></el-option>
				</el-select>
			</div>
		</div>
		<div class="center-panel"></div>
		<div class="right-panel"><slot></slot></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [],
			couponSelected: '',
			isack:true,
		};
	},
	created() {
		// 获取产品类型
		this.$HTTP.post('index/queryProduct', {}, response => {
			if (response.status >= 200 && response.status < 300) {
				if (response.data.code == 0) {
					this.options = response.data.data;
					if (this.$TOOL.data.get('DATA_SELECTEED') == null || this.$TOOL.data.get('DATA_SELECTEED') == '') {
						this.couponSelected = response.data.data[0].key;
						this.$TOOL.data.set("DATA_SELECTEED", this.couponSelected)
						this.$store.commit("setSelected",this.couponSelected)
					} else {
						this.couponSelected = this.$TOOL.data.get('DATA_SELECTEED');
						this.$store.commit("setSelected",this.couponSelected)
					}
				} else {
					this.$message.error(response.data.msg);
				}
			} else {
				console.log(response.message);
			}
		});
		
		if (document.body.clientWidth < 768) {
			this.isack = false
		} else {
			this.isack = true
		}
	},

	methods: {
		clselete() {
			this.$TOOL.data.set("DATA_SELECTEED", this.couponSelected)
			this.$store.commit("setSelected",this.couponSelected)
		},
		dcClick(e){
			if (e == true) {
				this.$store.commit('TOGGLE_menuIsCollapse')
			} else{
				this.$store.commit('TOGGLE_isact')
			}
		}
	},
	
};
</script>

<style scoped lang="scss">
.logo_center {
	display: flex;
	align-items: center;
	margin-left: 20px;
	font-weight:bold;
	font-size: 16px;
}
.adminui-topbar {
	color: #fff;
	height: 50px;
	border-bottom: 1px solid #ebeef5;
	background: #409eff;
	display: flex;
	justify-content: space-between;
	.left-panel {
		display: flex;
		align-items: center;
		.menu-collapse {
			height: 50px;
			width: 60px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			cursor: pointer;
			color: #fff;
			&:hover {
				background-image: linear-gradient(90deg, #d9ecff, transparent);
			}
		}
	}
	.right-panel {
		display: flex;
		align-items: center;
	}
}
</style>
