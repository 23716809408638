import Vuex from 'vuex'


//创建VueX对象
const store = new Vuex.Store({
	state: {
		viewTags: [],
		keepLiveRoute: [],
		routeKey: null,
		routeShow: true,
		//菜单是否折叠 toggle
		menuIsCollapse: false,
		dataSelected:'',
		isact:false,
		userid:''
	},

	getters: {},

	mutations: {
		//当前设备key
		setSelected(state, dataSel) {
			state.dataSelected = dataSel;
		},
		
		setuserid(state, uid) {
			state.userid = uid;
		},

		pushViewTags(state, route) {
			let target = state.viewTags.find((item) => item.fullPath === route.fullPath)
			let isName = route.name
			if (!target && isName) {
				state.viewTags.push(route)
			}
		},
		removeViewTags(state, route) {
			state.viewTags.forEach((item, index) => {
				if (item.fullPath === route.fullPath) {
					state.viewTags.splice(index, 1)
				}
			})
		},
		clearViewTags(state) {
			state.viewTags = []
		},

		pushKeepLive(state, component) {
			if (!state.keepLiveRoute.includes(component)) {
				state.keepLiveRoute.push(component)
			}
		},
		removeKeepLive(state, component) {
			var index = state.keepLiveRoute.indexOf(component);
			if (index !== -1) {
				state.keepLiveRoute.splice(index, 1);
			}
		},
		clearKeepLive(state) {
			state.keepLiveRoute = []
		},
		setRouteKey(state, key) {
			state.routeKey = key
		},
		setRouteShow(state, key) {
			state.routeShow = key
		},

		// 左侧菜单缩放
		TOGGLE_menuIsCollapse(state) {
			state.menuIsCollapse = !state.menuIsCollapse
		},
		TOGGLE_isact(state){
			state.isact = !state.isact
		}
	},
	actions: {
		setRouteKey({
			commit
		}, key) {
			commit('setRouteKey', key);
		}
	}

})

export default store
