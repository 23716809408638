import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import tool from '@/utils/tool';

// 首字母转大写
function firstUppercase(str) {
	return str[0].toUpperCase() + str.substring(1)
}

const routes = [{
		path: '/',
		name: 'home',
		component: Home,
		redirect: '/index', //默认显示页面
		children: [{
			path: '/index',
			name: 'index',
			meta: {
				title: '首页',
			},
			component: () => import('../views/index.vue')
		}, ]
	},
	{
		path: "/login",
		name: 'login',
		component: () => import('@/views/login'),
		meta: {
			title: "登录"
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})


//判断是否已加载过动态/静态路由
var isGetRouter = false;

// 登录权限
router.beforeEach(async (to, from, next) => {
	NProgress.start()
	let token = tool.data.get("TOKEN");
	if (to.path === "/login") {
		isGetRouter = false;
		next();
		return false;
	}

	if (!token) {
		next({
			path: '/login'
		});
		return false;
	}

	//加载动态/静态路由
	if (!isGetRouter) {
		isGetRouter = true;
		createNewRouter();
		next({
			...to,
			replace: true
		})
	} 
		next();

});


function createNewRouter() {
	var menuRouter = tool.data.get("USER_INFO").sysResourcesEntities || []
	menuRouter.forEach(item => {
		if (item.type == 'menu' && item.parentId != 0) {
			//home是父组件 add-route添加进父组件chilren里
			router.addRoute('home', {
				path: `${item.url}`,
				name: item.url.slice(1),
				meta: {
					title: item.name,
				},
				component: () => import(`../views/${firstUppercase(item.url.slice(1))}.vue`),
			})
		}
	})
}

router.afterEach(() => {
	NProgress.done()
});


export default router

// {
// 				path: '/index',
// 				name: 'index',
// 				meta: {
// 					title: '首页',
// 				},
// 				component: () => import('../views/index.vue')
// 			},
// 			{
// 				path: '/users',
// 				name: 'users',
// 				meta: {
// 					title: '用户列表',
// 				},
// 				component: () => import('../views/Users.vue')
// 			},
// 			{
// 				path: '/resources',
// 				name: 'resources',
// 				meta: {
// 					title: '权限管理',
// 				},
// 				component: () => import( '../views/Resources.vue')
// 			},
// 			{
// 				path: '/roles',
// 				name: 'roles',
// 				meta: {
// 					title: '角色管理',
// 				},
// 				component: () => import( '../views/Roles.vue')
// 			},
// 			{
// 				path: '/journal',
// 				name: 'journal',
// 				meta: {
// 					title: '操作日志',
// 				},
// 				component: () => import( '../views/Journal.vue')
// 			},
// 			{
// 				path: '/deleteDevice',
// 				name: 'deleteDevice',
// 				meta: {
// 					title: '删除测试用户',
// 				},
// 				component: () => import( '../views/DeleteDevice.vue')
// 			},
// 			{
// 				path: '/productInfo',
// 				name: 'productInfo',
// 				meta: {
// 					title: '产品信息',
// 				},
// 				component: () => import( '../views/ProductInfo.vue')
// 			},
// 			{
// 				path: '/gameConfig',
// 				name: 'gameConfig',
// 				meta: {
// 					title: '通用配置',
// 				},
// 				component: () => import( '../views/GameConfig.vue')
// 			},
// 			{
// 				path: '/prizeWheel',
// 				name: 'prizeWheel',
// 				meta: {
// 					title: '转盘配置',
// 				},
// 				component: () => import( '../views/PrizeWheel.vue')
// 			},
// 			{
// 				path: '/signInConfig',
// 				name: 'signInConfig',
// 				meta: {
// 					title: '签到配置',
// 				},
// 				component: () => import( '../views/SignInConfig.vue')
// 			},
// 			{
// 				path: '/tradeWithdraw',
// 				name: 'tradeWithdraw',
// 				meta: {
// 					title: '提现配置',
// 				},
// 				component: () => import( '../views/TradeWithdraw.vue')
// 			},
// 			{
// 				path: '/beatStageRewardConfig',
// 				name: 'beatStageRewardConfig',
// 				meta: {
// 					title: '过关奖励配置',
// 				},
// 				component: () => import( '../views/BeatStageRewardConfig.vue')
// 			},
// 			{
// 				path: '/userInfo',
// 				name: 'userInfo',
// 				meta: {
// 					title: '用户信息',
// 				},
// 				component: () => import( '../views/UserInfo.vue')
// 			},
// 			{
// 				path: '/tradeFundDetail',
// 				name: 'tradeFundDetail',
// 				meta: {
// 					title: '账户明细',
// 				},
// 				component: () => import( '../views/TradeFundDetail.vue')
// 			},
// 			{
// 				path: '/tradeFund',
// 				name: 'tradeFund',
// 				meta: {
// 					title: '账户信息',
// 				},
// 				component: () => import( '../views/TradeFund.vue')
// 			},
// 			{
// 				path: '/tradeWithdrawDetail',
// 				name: 'tradeWithdrawDetail',
// 				meta: {
// 					title: '提现明细',
// 				},
// 				component: () => import( '../views/TradeWithdrawDetail.vue')
// 			},