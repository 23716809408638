import axios from 'axios';
import router from '../router/index.js';
import tool from '@/utils/tool';
// 请求地址
// axios.defaults.baseURL = '/admin';
axios.defaults.baseURL = process.env.VUE_APP_SERVER;

let http = axios.create({
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
	},
});
if (process.env.NODE_ENV == 'development') {
	tool.data.set("COLORSHOWHIDE", true)
} else {
	tool.data.set("COLORSHOWHIDE", false)
}

function apiAxios(method, url, params, response) {

	http({
		method: method,
		url: url,
		data: method === 'POST' || method === 'PUT' ? params : null,
		params: method === 'GET' || method === 'DELETE' ? params : null,
	}).then(function (res) {
		if (res.data.code == -2) {
			router.push("/login");
		} else {
			response(res);
		}
	}).catch(function (err) {
		response(err);
	})
}
// Excel 导出
function apiAxios2(method, url, params, response) {
	http({
		method: method,
		url: url,
		data: method === 'POST' || method === 'PUT' ? params : null,
		params: method === 'GET' || method === 'DELETE' ? params : null,
		responseType: 'blob'
	}).then(function (res) {
		if (res.data.code == -2) {
			router.push("/login");
		} else {
			response(res);
		}
	}).catch(function (err) {
		response(err);
	})
}
export default {
	post: function (url, params, response) {
		return apiAxios('POST', url, params, response)
	},
	get: function (url, params, response) {
		return apiAxios('GET', url, params, response)
	},
	updateHeader: function (key, params) {
		http.defaults.headers.common[key] = params;
	},
	// Excel 导出
	post2: function (url, params, response) {
		return apiAxios2('POST', url, params, response)
	},


	exportExcel: function (export_data, list_name) {
		let link = document.createElement("a");
		//    type就是blob的type,是MIME类型的，可以自己查看MIME类型都有哪些
		let blogw = new Blob([export_data], {
			type: "application/vnd.ms-excel;charset=utf-8"
		})
		let objectUrl = window.URL.createObjectURL(blogw); //创建一个新的url对象
		link.href = objectUrl;
		let file_name = `${list_name}.xlsx`;
		link.download = file_name; //  下载的时候自定义的文件名
		link.click();
		window.URL.revokeObjectURL(objectUrl); //为了更好地性能和内存使用状况，应该在适当的时候释放url.

	}
}
